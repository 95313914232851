/**
 * IMPORTANT: This file is for dealer-franchisee app initialization.
 * do not use this file for other apps.
 * DO not import anything from the main application. this forms a stand alone
 * initialization for the dealer-franchisee app.
 *
 */

function getAssetCdnPath(fileName: string): string {
  if (fileName.startsWith('./')) {
    fileName = fileName.substring(2);
  } else if (fileName.startsWith('/')) {
    fileName = fileName.substring(1);
  } else if (fileName.startsWith('assets/')) {
    fileName = fileName.substring(7);
  } else if (fileName.startsWith('./assets/')) {
    fileName = fileName.substring(9);
  }

  return `https://webmodule-softtech.azureedge.net/v6config/site/assets/${fileName}`;
}

export function documentReady(fn) {
  // see if DOM is already available
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    window.addEventListener('load', fn);
  }
}

let apiHost;

export async function initApiHost() {
  updateNote('Fetching Api Server Connection Settings');
  apiHost = await getFile('./assets/apiserver.json', 'Cannot Access Api Settings', expiresIn.oneWeek);
  if (!apiHost) {
    retryPage();
    return false;
  }
  return true;
}

let info;

export function updateNote(note) {
  if (!info) info = document.body.querySelector('#loadingNote');
  if (info) info.innerHTML = note;
}

export let leaveBackground = false;
let _skipBackgroundProcessing = false;

export function skipBackgroundProcessing() {
  _skipBackgroundProcessing = true;
}

export function retryPage() {
  leaveBackground = true;
  let counter = 60;
  const msg = currentNote();
  const countDown = () => {
    updateNote(`${msg}\nAttempting Page Reload in ${counter--} seconds`);
    if (counter === 0) {
      location.reload();
    } else setTimeout(countDown, 1000);
  };
  setTimeout(countDown, 1000);
}

export async function getFile(path, issue, expires = 604800, force = false) {
  try {
    const response = globalThis.noSqlDb
      ? await globalThis.noSqlDb.fetch(path, expires, undefined, force)
      : await fetch(path, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        });
    if (response) return response;
    else updateNote(issue ?? `Could not access "${path}"`);
    return undefined;
  } catch {
    updateNote(issue ?? `Could not access "${path}"`);
    return undefined;
  }
}

export const expiresIn = {
  oneDay: 60 * 60 * 24,
  oneWeek: 60 * 60 * 24 * 7,
  oneMonth: 60 * 60 * 24 * 30,
  nextDay: -100
};

export function currentNote() {
  if (!info) info = document.body.querySelector('#loadingNote');
  return info?.innerHTML;
}

export async function checkToClearDB() {
  let flushNoSql = localStorage.getItem('flush-no-sql') === 'true';
  if (flushNoSql) {
    globalThis.noSqlDb?.clear?.();
    localStorage.removeItem('flush-no-sql');
  }
}

export async function initializeLanguages() {
  updateNote('Fetching Language Settings');
  let langFile = await getFile(
    './assets/lang/lang.json',
    'Cannot Access LangFile, Applying defaults',
    expiresIn.oneMonth
  );
  if (!langFile) langFile = {};

  updateNote('Fetching Technical Dictionary');
  const dictItems = await getFile(
    `${apiHost.host}/api/lang/dict.json`,
    'Cannot access Technical Dictionary',
    expiresIn.oneMonth
  );

  globalThis.dealerConfiguration.langFile = langFile;
  globalThis.dealerConfiguration.dictItems = dictItems ?? null;
}

export async function reloadPageIfOutOfDate(isOutOfDate) {
  if (isOutOfDate) {
    if (globalThis.runningIsOutOfDate) return;
    globalThis.runningIsOutOfDate = true;
    try {
      await globalThis.noSqlDb?.clear?.();
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      /*
            const msg = `
      The Dealer system is now out-of-sync due to a recent scheduled update and will refresh.
      
      You will lose any unsaved changes. 
      
          Current Server Version: ${globalThis.dealerConfiguration.apiBuildNumberDisplay}
          
          Current Local Version: ${globalThis.localBuildNumber ?? 'Unknown'}
          
          Should this not resolve, please contact support for assistance
      
      **Tip:** To avoid this issue we recommend logging out overnight or when not in use to reduce the risk of data loss during scheduled updates.
                  `;
      
            try {
              if (globalThis.informAsync) await globalThis.informAsync?.(msg, 'System out-of-date');
              else alert(msg);
            } catch {
              alert(msg);
            }
            
       */
    } finally {
      window.location.reload();
    }
  }
}

globalThis.reloadPageIfOutOfDate = reloadPageIfOutOfDate;
export let branding;

export async function initBranding() {
  let branding = await globalThis.noSqlDb?.getItem('supplier-branding');
  if (branding) document.body.style.backgroundImage = `url('${branding.loginImageUrl}')`;
}

export async function refreshBranding(licenseServerHost: string, dealerDeploymentId: number) {
  updateNote('Fetching Branding for site');

  if (!branding) await updateBranding(licenseServerHost, dealerDeploymentId);
  else updateBranding(licenseServerHost, dealerDeploymentId);
}

async function updateBranding(licenseServerHost: string, dealerDeploymentId: number) {
  try {
    branding = await getFile(
      `${licenseServerHost}/api/deployment/DomainBranding?id=${dealerDeploymentId}`,
      'Branding could not be accessed',
      expiresIn.nextDay
    );
    //branding doesn't change often. we are going to store the branding for this domain
    //here, so that next refresh we can push the value immediately into the background
    await globalThis.noSqlDb?.setItem('supplier-branding', branding);
  } catch {
    branding = {
      loginImageUrl: getAssetCdnPath('./assets/images/login-img.png'),
      loginBrandLogo: getAssetCdnPath('./assets/images/PoweredBySoftTech-blue.svg'),
      footerBrandLogo: getAssetCdnPath('./assets/images/brandingfooterlogo.png'),
      operationType: 0
    };
  }
  //this might be happening in the background without awaiting, so make sure we
  //only add it, if we need to
  if (!_skipBackgroundProcessing || (_skipBackgroundProcessing && leaveBackground))
    document.body.style.backgroundImage = `url('${branding.loginImageUrl}')`;
}

export async function refreshApiDetailsAndUpdateDealerConfiguration(apiHost: string, force = true) {
  const details = await getFile(
    `${apiHost}/api/System/ApiDetails`,
    'Cannot Access Api Server, Possibly Offline',
    expiresIn.oneDay,
    force
  );
  if (details && globalThis.dealerConfiguration) {
    globalThis.dealerConfiguration.v6apiHost = details.otherApiDomains.v6 ?? apiHost;
    globalThis.dealerConfiguration.licenseServerHost = details.licenseServerHost;
    globalThis.dealerConfiguration.dealerDeploymentId = details.dealerDeploymentId;
    globalThis.dealerConfiguration.apiBuildNumber = details.buildNumberAsInt ?? 0;
    globalThis.dealerConfiguration.apiBuildNumberDisplay = details.buildNumber ?? '';
  }
  return details;
}

export async function initializeApiDetails(elementName: string, applicationTitle: string) {
  globalThis.dealerRefreshApiDetails = () => refreshApiDetailsAndUpdateDealerConfiguration(apiHost.host);

  updateNote('Fetching License Server Connection Settings');
  let apiDetails = await refreshApiDetailsAndUpdateDealerConfiguration(apiHost.host, false);
  if (!apiDetails) {
    retryPage();
    return;
  }

  if (globalThis.isAppOutOfDate(apiDetails.buildNumber) && globalThis.noSqlDb.lastFetch) {
    //there is a chance we think we are out of date, because of a stale DB fetch,
    //when a force reload has occurred, so do another refresh with good data
    apiDetails = await globalThis.dealerRefreshApiDetails();
  }

  await refreshBranding(apiDetails.licenseServerHost, apiDetails.dealerDeploymentId);
  try {
    globalThis.dealerConfiguration = {
      apiHost: apiHost.host,
      v6apiHost: apiDetails.otherApiDomains.v6 ?? apiHost.host,
      licenseServerHost: apiDetails.licenseServerHost,
      dealerDeploymentId: apiDetails.dealerDeploymentId,
      apiBuildNumber: apiDetails.buildNumberAsInt ?? 0,
      apiBuildNumberDisplay: apiDetails.buildNumber ?? '',
      v6apiBuildNumber: 0, //not needed by supplier
      v6apiBuildNumberDisplay: '', //not needed by supplier
      lang: {},
      dictItems: null,
      branding: branding
    };
    globalThis.bindApplicationFeatures?.();
    await reloadPageIfOutOfDate(globalThis.isAppOutOfDate());
    await initializeLanguages();

    //this will connect to the server to see if the current
    //login token is valid, and that the current user is allowed
    //to be on this session (ie not in another session)
    //if false, then this will disconnect the user
    updateNote('Preparing Application');
    await globalThis.getApplicationStartupEvents();
    //If we got to this point, then everything that needed doing has been done.
    //if we need to logoff from here, we should not need to reload the page to do it
    const mainElement = document.createElement(elementName);
    mainElement.setAttribute('appTitle', applicationTitle);
    mainElement.className = 'app-wrapper';
    document.body.querySelector('#splash-screen')?.remove();

    document.body.insertBefore(mainElement, mainElement.firstChild);
  } finally {
    skipBackgroundProcessing();
    if (!leaveBackground) document.body.style.backgroundImage = '';
  }
}
